import "slick-carousel"

$ ->
  if $('#js-rich-content, #js-hotels-theme').length > 0
    # カバー
    $('.js-slick-slider-cover-image').slick
      autoplay: true
      autoplaySpeed: 3000
      arrows: false
      cssEase: 'linear'
      dots: false
      fade: true
      infinite: true
      speed: 800
      slidesToShow: 1
      zIndex: 1
    # slick-carousel [都道府県×ホテル]
    $('.js-slick-slider-image').slick
      infinite: true
      dots: true
      slidesToShow: 1
