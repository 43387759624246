import lozad from "lozad";
import { bindSpotMapEventTriggers } from "javascripts/webpage/spot-map";
import { bindMicromodalEventTrigger } from "javascripts/micromodal";
import { handleLink } from "javascripts/webpage/link";
import { insertAbout } from "javascripts/webpage/insert-about";
import { headerScroll } from "javascripts/webpage/sp/header-scroll";

document.addEventListener("DOMContentLoaded", () => {
  function pollHotels() {
    const counter = document.querySelector(".js-search-counter");
    fetch(document.querySelector(".js-polling-url").dataset.pollingUrl)
      .then((response) => {
        if (!response.ok) {
          throw "ErrorException";
        }
        return response.json();
      })
      .then((data) => {
        if (data.html) {
          document.querySelector(".js-loader").style.display = "none";
          searchList.innerHTML = data.html;
          if (data.counter) {
            counter.innerHTML = data.counter;
          } else {
            counter.innerHTML = "";
          }
          const pagination = document.querySelector(".js-pagination");
          if (data.pagination) {
            pagination.innerHTML = data.pagination;
          }
          const addedElements = searchList.querySelectorAll(".js-lazy:not([data-loaded])");
          const newObserver = lozad(addedElements, {
            rootMargin: "200% 400%",
          });
          newObserver.observe();
          document.querySelectorAll("[class*=js-clip-]").forEach((clip) => {
            clip.classList.remove("c-clip--hide");
          });
          bindSpotMapEventTriggers();
          bindMicromodalEventTrigger(searchList);
          searchList.querySelectorAll(".js-link").forEach((link) => {
            link.addEventListener("click", handleLink);
          });
          insertAbout();
          headerScroll();
        } else {
          setTimeout(pollHotels, 1000);
        }
      })
      .catch(() => {
        document.querySelector(".js-loader").style.display = "none";
        document.querySelector(".js-search-list").innerHTML = `
          <div class="c-no-results">
            <h3 class="c-no-results__heading">サーバーエラー</h3>
            <p class="c-no-results__description">
              ただいまサイトへのアクセスが大変混み合っております。
              <br>
              しばらくたってから再度ご利用ください。
            </p>
          </div>`;
      });
  }
  const searchList = document.querySelector(".js-search-list");
  if (searchList) {
    const counter = document.querySelector(".js-search-counter");
    counter.innerHTML += '<div class="p-hotels-shared-loader-counter js-loader"></div>';
    searchList.innerHTML += '<div class="p-hotels-shared-loader js-loader">ホテルを取得中です</div>';
    pollHotels();
  }
});
