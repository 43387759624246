import AOS from "aos/dist/aos";

// default setting
AOS.init({
  offset: 40,
  duration: 600,
  esing: "ease-in-sine",
  delay: 100,
  once: true,
  useClassNames: false,
  initClassName: false,
  animatedClassName: "is-animated",
});
