/* global grecaptcha */

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.querySelectorAll("[data-google-recaptcha-action]")).forEach((node) => {
    const action = node.dataset.googleRecaptchaAction;
    const sitekey = node.dataset.googleRecaptchaSitekey;
    node.closest("form").addEventListener(
      "submit",
      function (event) {
        grecaptcha.ready(() => {
          try {
            grecaptcha.execute(sitekey, { action: action }).then(
              (token) => {
                node.value = token;
                this.submit();
              },
              () => {
                alert("reCAPTCHAによる検証が行えませんでした");
              },
            );
          } catch (e) {
            alert("reCAPTCHAによる検証が行えませんでした");
          }
        });
        event.preventDefault();
      },
      false,
    );
  });
});
