import Sortable from "sortablejs/Sortable";

document.addEventListener("DOMContentLoaded", () => {
  if (!document.getElementById("js-bulk_clips-edit")) {
    return;
  }
  const press = document.querySelector(".js-sortable-press");
  const spot = document.querySelector(".js-sortable-spot");
  const handles = document.querySelectorAll(".js-sortable-handle");

  [press, spot].forEach((sortableElement) => {
    if (sortableElement) {
      new Sortable.create(sortableElement, {
        group: sortableElement,
        handle: ".js-sortable-handle",
        animation: 100,
      });
    }
  });
  handles.forEach((handle) => {
    handle.classList.add("c-sortable__handle");
    handle.style.display = "flex";
  });
});
