import ClipboardJS from "clipboard";
import tippy from "tippy.js";

document.addEventListener("DOMContentLoaded", () => {
  tippy(".js-clipboard", {
    arrow: true,
    animation: "shift-away",
    delay: 300,
    placement: "bottom",
    trigger: "click",
    theme: "dark",
    onShown: (instance) => {
      setTimeout(() => {
        instance.hide();
      }, 1000);
    },
  });
  const clipboard = new ClipboardJS(".js-clipboard", {
    container: document.getElementById("target-clipboard"),
  });
  clipboard.on("success", (event) => {
    event.clearSelection();
  });
});
