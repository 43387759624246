export const fadeIn = (elem, duration = 300, display = "block") => {
  if (elem.style.display !== "none") return;
  elem.style.opacity = 0;
  elem.style.display = display;

  const start = performance.now();
  requestAnimationFrame(function tick(timestamp) {
    const easing = (timestamp - start) / duration;
    elem.style.opacity = Math.min(easing, 1);
    if (easing < 1) {
      requestAnimationFrame(tick);
    } else {
      elem.style.opacity = "";
    }
  });
};

export const fadeOut = (elem, duration = 300) => {
  if (elem.style.display === "none") return;

  const start = performance.now();
  requestAnimationFrame(function tick(timestamp) {
    const easing = (timestamp - start) / duration;
    elem.style.opacity = 1 - easing;
    if (easing < 1) {
      requestAnimationFrame(tick);
    } else {
      elem.style.display = "none";
      elem.style.opacity = 0;
    }
  });
};
