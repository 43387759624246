document.addEventListener("DOMContentLoaded", function () {
  if (!document.querySelector(".modal")) {
    return;
  }
  const modal = document.querySelectorAll(".modal");
  modal.forEach(function (modal) {
    modal.addEventListener("show.bs.modal", function () {
      document.querySelectorAll("img.js-lazy").forEach((element) => element.setAttribute("src", element.getAttribute("data-src")));
    });
  });
});
