document.addEventListener("DOMContentLoaded", () => {
  headerScroll();
});

function headerScroll() {
  const header = document.getElementsByClassName("js-header")[0];
  if (header) {
    const headerHeight = header.clientHeight;
    let offset = 0,
      lastPosition = 0,
      ticking = false,
      windowBottom = document.body.scrollHeight - window.innerHeight;

    const update = () => {
      ticking = false;
      // 透過処理対応
      if (typeof header.dataset.transparent !== "undefined") header.classList.toggle("l-header--transparent", lastPosition <= 100);

      const scrollDirDown = lastPosition > offset || lastPosition >= windowBottom;
      if ((typeof header.dataset.banner !== "undefined" && lastPosition >= 320) || lastPosition > headerHeight) {
        document.body.dataset.scrollDir = scrollDirDown ? "down" : "up";
      }
      offset = lastPosition;
    };

    const onScroll = () => {
      lastPosition = window.scrollY;
      if (!ticking) {
        window.requestAnimationFrame(() => update());
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll, !document.documentMode ? { passive: true } : false);
    onScroll();
  }
}

export { headerScroll };
