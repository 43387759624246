document.addEventListener("DOMContentLoaded", () => {
  if (!document.getElementById("js-inquiries")) {
    return;
  }
  const selectElement = document.querySelector(".js-inquiry-title");
  const infringement = document.querySelector(".js-inquiry-infringement");
  const advertisement = document.querySelector(".js-inquiry-advertisement");
  const aboutPress = document.querySelector(".js-inquiry-about-press");
  const display = "c-form__hide";
  if (selectElement) {
    selectElement.addEventListener("change", () => {
      const optionValue = selectElement.value;
      optionValue == "infringement" ? infringement.classList.remove(display) : infringement.classList.add(display);
      optionValue == "advertisement" ? advertisement.classList.remove(display) : advertisement.classList.add(display);
      optionValue == "about_press" ? aboutPress.classList.remove(display) : aboutPress.classList.add(display);
    });
  }
});
