import Cookies from "js-cookie";

const insertAbout = () => {
  const COOKIE_KEY = "hide_insert_about";
  const content = document.getElementById("js-insert-about");

  // TODO: #10321 信頼性情報Cookie制御を一時的に無効（Cookieを削除）
  Cookies.remove(COOKIE_KEY);

  if (!content) {
    return;
  }
  // TODO: #10321 信頼性情報Cookie制御を一時的に無効
  // Cookies.get(COOKIE_KEY) ? content.remove() : Cookies.set(COOKIE_KEY, "1", { expires: 30 });
};

export { insertAbout };
