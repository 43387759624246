document.addEventListener("DOMContentLoaded", () => {
  const active = "is-active";
  const dropdown = ".js-dropdown-list";
  const dropdownList = document.querySelectorAll(dropdown);

  const dropdownOpen = (open) => {
    open.target.parentNode.classList.add(active);
  };
  const dropdownClose = (close) => {
    close.classList.remove(active);
  };
  dropdownList.forEach((items) => {
    items.addEventListener("click", (item) => {
      const isActive = item.target.parentNode.classList.contains(active);
      const openList = document.getElementsByClassName(active)[0];
      if (openList) {
        dropdownClose(openList);
      }
      if (!isActive) {
        dropdownOpen(item);
      }
    });
  });
  document.addEventListener("click", (event) => {
    if (!event.target.closest(dropdown)) {
      Array.from(dropdownList).forEach((list) => {
        list.classList.remove(active);
      });
    }
  });
});
