$(() => {
  // ユーザーアバターでファイルを選択したときに選択したことが分かるように
  $("#js-user-avatar-file-field").on("change", ({ target }) => {
    if ($(target).val()) {
      $("#js-user-avatar-file-status").text("写真選択済み");
    } else {
      $("#js-user-avatar-file-status").text("写真を選択する");
    }
  });

  // 階層型のチェックボックスで一括チェック
  $(".js-narrow-down-check").on("change", function () {
    const $wrapper = $(this).parent(".js-narrow-down-item");
    // 対象の下位チェックを全切り替え
    $wrapper.find(".js-narrow-down-check").prop("checked", $(this).is(":checked"));
    // 同列のチェック状況で上位のチェックを切り替え
    $wrapper.parents(".js-narrow-down-item").each(function () {
      const $checkboxes = $(this).find("[class|=p-users-keywords__item] .js-narrow-down-check");
      const allCheck = $checkboxes.length == $checkboxes.filter(":checked").length;
      $(this).children(".js-narrow-down-check").prop("checked", allCheck);
    });
  });

  // 選択中のアイテムをクリア
  $(".js-narrow-clear-area").on("click", () => {
    $("#target-search-area").find("input:checkbox").prop("checked", false);
    $("#js-narrow-form").submit();
  });
  $(".js-narrow-clear-keyword").on("click", () => {
    $("#target-search-keyword").find("input:checkbox").prop("checked", false);
    $("#js-narrow-form").submit();
  });
  $(".js-narrow-clear-type").on("click", () => {
    $("#target-search-type").find("input:checkbox").prop("checked", false);
    $("#js-narrow-form").submit();
  });

  // カタリスト計測
  // eslint-disable-next-line no-undef
  $(".js-narrow-clipchoice").on("click", ({ target }) => onclickcatalyst_clipchoice(target, $(target).data("clipchoice")));
});
