import "slick-carousel"

$ ->
  if $("#js-specials").length > 0
    if $('.p-specials').length > 0
      # slick-carousel
      $('.js-slick-slider-image').slick
        arrows        : false
        autoplay      : true
        autoplaySpeed : 4000
        cssEase       : 'linear'
        dots          : false
        fade          : true
        infinite      : true
        speed         : 800
        slidesToShow  : 1
        zIndex        : 1
