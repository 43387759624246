import * as fade from "../common/fade";
import { isSmartPhone } from "../common/user-agent";

export default class Toast {
  constructor(elem, aditional_config) {
    this._timeout = null;
    this._hasMouseInteraction = false;
    this._hasKeyboardInteraction = false;
    this._element = elem;
    this._config = {
      ...this.default_config(),
      ...aditional_config,
    };

    // NOTE: removeEventListenerするために使用するfunctionをここで固定しています
    this._mouseoverEventHandler = function (event) {
      this._onInteraction(event, true);
    }.bind(this);
    this._mouseoutEventHandler = function (event) {
      this._onInteraction(event, false);
    }.bind(this);
    this._focusinEventHandler = function (event) {
      this._onInteraction(event, true);
    }.bind(this);
    this._focusoutEventHandler = function (event) {
      this._onInteraction(event, false);
    }.bind(this);
    this._setListeners();
  }

  default_config() {
    /**
     * @type   {object}
     * @return {number}  delay          - Toastを表示する時間（ms）
     * @return {boolean} autohide       - true or false: falseにすると自動でToastが消えなくなります。
     * @return {string}  showingDisplay - Toastを表示する際のdisplayの値を変更できます。
     * @return {array}   showingClasses - Toastを表示するときに付加したいクラスを配列で指定してください。hideされる時に削除されます。
     * @return {array}   hidingClasses  - Toastを非表示にするときに付加したいクラスを配列で指定してください。showされる時に削除されます。
     */
    return {
      delay: 3500,
      autohide: true,
      showingDisplay: isSmartPhone ? "flex" : "block",
      showingClasses: ["fade", "show"],
      hidingClasses: ["fade", "hide"],
    };
  }

  show() {
    this._element.classList.remove(...this._config.hidingClasses);
    this._element.classList.add(...this._config.showingClasses);
    fade.fadeIn(this._element, 300, this._config.showingDisplay);

    this._maybeScheduleHide();
  }

  _setListeners() {
    this._element.addEventListener("mouseover", this._mouseoverEventHandler);
    this._element.addEventListener("mouseout", this._mouseoutEventHandler);
    this._element.addEventListener("focusin", this._focusinEventHandler);
    this._element.addEventListener("focusout", this._focusoutEventHandler);
  }

  _removeListeners() {
    this._element.removeEventListener("mouseover", this._mouseoverEventHandler);
    this._element.removeEventListener("mouseout", this._mouseoutEventHandler);
    this._element.removeEventListener("focusin", this._focusinEventHandler);
    this._element.removeEventListener("focusout", this._focusoutEventHandler);
  }

  _clearTimeout() {
    clearTimeout(this._timeout);
    this._timeout = null;
  }

  _maybeScheduleHide() {
    if (!this._config.autohide) {
      return;
    }

    if (this._hasMouseInteraction || this._hasKeyboardInteraction) {
      return;
    }

    this._timeout = setTimeout(() => {
      this._element.classList.remove(...this._config.showingClasses);
      this._element.classList.add(...this._config.hidingClasses);
      fade.fadeOut(this._element);
      this._removeListeners();
    }, this._config.delay);
  }

  // NOTE: Toast上にマウスを当てたり、キーボードで選択する時にisInteractingをtrueにすることでToastが消えないようにしています
  _onInteraction(event, isInteracting) {
    switch (event.type) {
      case "mouseover":
      case "mouseout":
        this._hasMouseInteraction = isInteracting;
        break;
      case "focusin":
      case "focusout":
        this._hasKeyboardInteraction = isInteracting;
        break;
      default:
        break;
    }

    if (isInteracting) {
      this._clearTimeout();
      return;
    }

    // NOTE: マウスカーソルでToast内のパーツをhoverしている時はToastが消えないようにしています
    const nextElement = event.relatedTarget;
    if (this._element === nextElement || this._element.contains(nextElement)) {
      return;
    }

    this._maybeScheduleHide();
  }
}
