import "slick-carousel"
import bootstrap from "bootstrap/dist/js/bootstrap"
import tippy from "tippy.js";

$ ->
  if $('#js-presses, #js-preview_drafts').length > 0

    # 目次の開閉
    viewMoreContent = $('.js-viewmore-content')
    viewMoreOpen = $('.js-viewmore-open')
    viewMoreClose = $('.js-viewmore-close')

    viewMoreClose.hide()
    viewMoreOpen.on 'click', ->
      $(this).hide()
      viewMoreClose.css 'display', 'block'
      viewMoreContent.css 'display', 'block'
      viewMoreContent.animate { height: viewMoreContent.prop('scrollHeight') }
      return
    viewMoreClose.on 'click', ->
      $(this).hide()
      viewMoreOpen.css 'display', 'block'
      $('html, body').animate { scrollTop: $('.p-presses-show__toc-container').offset().top - 60 }, 200, 'swing', ->
        viewMoreContent.css 'display', 'inline-block'
        viewMoreContent.animate height: '120px', 200
      return

    # 目次へ戻るボタン
    returnTocLink = $('.js-return-toc')
    returnTocLink.on 'click', ->
      viewMoreOpen.hide()
      viewMoreClose.css 'display', 'block'
      viewMoreContent.css 'display', 'block'
      viewMoreContent.animate { height: viewMoreContent.prop('scrollHeight') }

    # 最後のパーツにクラスを付与
    $('.js-parts:last').addClass('p-presses-show__part-content--last')

    # スライドショーパーツ
    $('.js-slick-slideshow-wrap').each (index) ->
      $(@).on 'init reInit', (event, slick) -> $(@).find('.js-slick-slideshow-count').text("1 / " + slick.slideCount)
      .on 'afterChange', (event, slick, current) ->
        $(@).find('.js-slick-slideshow-count').text("" + (current + 1) + " / " + slick.slideCount)
        if current > $(@).data('slide-evented')
          $(@).data('slide-evented', current)
          s = s_gi(s_account)
          s.linkTrackVars = 'channel,prop1,prop35'
          s.linkTrackEvents = 'None'
          s.channel = '画像スライド'
          s.prop1 = '画像スライド'
          s.prop35 = [$(@).data('id'), current + 1, slick.slideCount].join('_')
          s.tl(this, 'o', s.prop1)
      .find('.js-slick-slideshow-image').slick
        arrows          : true
        centerMode      : true
        dots            : false
        focusOnSelect   : true
        infinite        : false
        slidesToShow    : 1
        responsive: [
          {
            breakpoint       : 99999
            settings         :
              centerPadding  : '0'
          }
          {
            breakpoint       : 600
            settings         :
              centerPadding  : '20px'
          }
        ]

    # チェックリスト、口コミパーツのもっと見るボタン
    $('.js-more-button').on 'click', ->
      $(@).hide()
      # チェックリスト
      $(@).parent('.js-checklist-more').removeClass('p-presses-show-checklist--hide')
      # 口コミパーツ
      $(@).parent('.js-review-more').removeClass('p-presses-show-review--hide')
      # ホテル比較表（スペック）
      $(@).parent('.js-specs-compare-more').removeClass('p-presses-show-specs-compare--hide')

    # ホテル比較表
    tippy '.js-popover',
      arrow: true
      animation: 'shift-away'
      delay: 300
      placement: 'bottom'
      trigger: 'click'
      theme: 'light'
