const replaceNoImage = (imageElement) => {
  const containerElement = imageElement.closest(".js-instagram-image-container");
  if (containerElement) {
    while (containerElement.firstChild) {
      containerElement.removeChild(containerElement.firstChild);
    }
    const noImage = document.createElement("div");
    noImage.classList.add("p-presses-show-image--empty");
    containerElement.appendChild(noImage);
  }
};

document.addEventListener("DOMContentLoaded", () => {
  const instagramImages = document.getElementsByClassName("js-instagram-image");
  Array.from(instagramImages).forEach((image) => {
    if (image.src.match(/^data:image.+/)) {
      image.addEventListener("error", (event) => {
        replaceNoImage(event.target);
      });
    } else if (image.complete) {
      // NOTE: https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/naturalWidth#value
      if (image.naturalHeight === 0 && image.naturalWidth === 0) {
        replaceNoImage(image);
      }
    } else {
      image.addEventListener("error", (event) => {
        replaceNoImage(event.target);
      });
    }
  });
});
