document.addEventListener("DOMContentLoaded", () => {
  const itemClass = ".js-tab-item";
  const contentsClass = ".js-tab-contents";
  const activeClass = "is-tab-active";
  const tabItems = document.querySelectorAll(itemClass);
  const tabContents = document.querySelectorAll(contentsClass);
  const tabList = document.querySelectorAll(".js-tab-list");
  const tabInterlocking = document.querySelector(".js-tab-interlocking");

  const itemShow = (show) => {
    show.classList.add(activeClass);
  };
  const itemHide = (hide) => {
    hide.classList.remove(activeClass);
  };
  const contentShow = (show) => {
    show.classList.add(activeClass);
    show.style.display = "inherit";
  };
  const cotentHide = (hide) => {
    hide.classList.remove(activeClass);
    hide.style.display = "none";
  };
  tabItems.forEach((tabItem) => {
    tabItem.addEventListener("click", () => {
      // Tripリスト追加ページの検索ボックスの切り替えの場合は`data-toggle`を使用
      const tripListsNew = document.getElementById("js-trip-lists-new");
      const target = tabItem.getAttribute(tripListsNew ? "data-toggle" : "data-tab");

      tabItems.forEach((item) => {
        itemHide(item);
      });
      tabContents.forEach((content) => {
        cotentHide(content);
      });
      itemShow(tabItem);
      const tabTarget = document.getElementById(target);
      if (tabTarget) {
        contentShow(tabTarget);
      }
      // 新ホテル詳細ページのタブは上下連動で切り替え
      const tabIndex = Array.from(tabItem.parentElement.querySelectorAll(itemClass)).indexOf(tabItem);
      tabList.forEach((items) => {
        items.querySelectorAll(itemClass).forEach((item, index) => {
          itemHide(item);
          if (index === tabIndex) {
            itemShow(item);
          }
        });
      });
      tabContents.forEach((content) => {
        cotentHide(content);
        const contents = Array.from(content.parentElement.querySelectorAll(contentsClass));
        if (contents.indexOf(content) === tabIndex) {
          contentShow(content);
        }
      });
    });
  });
  // 複数タブに対応
  document.addEventListener("click", (event) => {
    const tabItemClass = ".js-tab-item--multi";
    const containerClass = ".js-tab-container";
    if (event.target.matches(tabItemClass)) {
      const tabContainer = document.querySelector(containerClass);
      const tabItems = tabContainer.querySelectorAll(tabItemClass);
      const tabContents = tabContainer.querySelectorAll(contentsClass);
      tabItems.forEach((item) => {
        itemHide(item);
      });
      itemShow(event.target);
      const tabIndex = Array.from(tabItems).indexOf(event.target);
      tabContents.forEach((content) => {
        cotentHide(content);
      });
      if (tabContents[tabIndex]) {
        contentShow(tabContents[tabIndex]);
      }
    }
  });
  // 別ページから遷移して過ごし方のタブを開く
  if (tabInterlocking) {
    const location = window.location.hash;
    let locationMatch = location.substring(1);
    if (locationMatch) {
      const adjustedPosition = setInterval(() => {
        let target = document.getElementById(locationMatch);
        if (target) {
          const tabIndex = Array.prototype.indexOf.call(target.parentNode.childNodes, target);
          const tabCount = Array.prototype.indexOf.call(document.querySelectorAll(itemClass, target.parentNode), this);
          tabList.forEach((items) => {
            const tabItems = Array.from(items.querySelectorAll(itemClass));
            tabItems.forEach((item, index) => {
              if (index === tabIndex || index === tabCount) {
                tabItems.forEach((item) => {
                  itemHide(item);
                });
                itemShow(item);
              }
            });
          });
          tabContents.forEach((content, index) => {
            const modelCase = location.match(/^#target-model-case-tab-\d+$/)?.input;
            if (index === tabIndex || index === tabCount) {
              contentShow(content);
            } else if (modelCase) {
              cotentHide(content);
            }
          });
        }
        clearInterval(adjustedPosition);
      });
    }
  }
});
