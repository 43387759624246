const backfaceFixed = (fixed) => {
  const body = document.body;
  const scrollbarWidth = window.innerWidth - body.clientWidth;
  body.style.paddingRight = fixed ? `${scrollbarWidth}px` : "";

  const scrollingElement = () => {
    const browser = window.navigator.userAgent.toLowerCase();
    if ("scrollingElement" in document) return document.scrollingElement;
    if (browser.indexOf("webkit") > 0) return body;
    return document.documentElement;
  };

  const scrollY = fixed ? scrollingElement().scrollTop : parseInt(body.style.top || 0);
  const styles = {
    position: "fixed",
    top: `${scrollY * -1}px`,
    left: "0",
    width: `calc(100vw - ${scrollbarWidth}px)`,
    overflow: "hidden",
  };

  Object.keys(styles).forEach((key) => {
    body.style[key] = fixed ? styles[key] : "";
  });
  if (!fixed) window.scrollTo(0, scrollY * -1);
};
export { backfaceFixed };
