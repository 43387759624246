import ScrollBooster from "scrollbooster";

document.addEventListener("DOMContentLoaded", () => {
  swipeForWindows();
});

function swipeForWindows() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.indexOf("windows nt") !== -1) {
    const viewports = document.querySelectorAll(".js-scroll-booster");
    if (viewports) {
      viewports.forEach((viewport) => {
        viewport.setAttribute("scrollbooster", true);
        const list = viewport.querySelector(".js-scroll-booster-list");
        list.style.cursor = "grab";
        new ScrollBooster({
          viewport: viewport,
          scrollMode: "native",
          direction: "horizontal",
          bounce: false,
          emulateScroll: true,
        });
      });
    }
  }
}
export { swipeForWindows };
