import MicroModal from "micromodal";
import { backfaceFixed } from "./backface-fixed";

const defaultOptions = {
  awaitOpenAnimation: true,
  awaitCloseAnimation: true,
  disableFocus: true,
  disableScroll: true,
  debugMode: false,
  onShow: () => {
    backfaceFixed(true);
  },
  onClose: () => {
    backfaceFixed(false);
  },
};
const micromodal = (id, options = {}) => {
  const element = document.getElementById(id);
  if (element) {
    const mergedOptions = Object.assign({}, defaultOptions, options);
    selfClosing();
    MicroModal.show(id, mergedOptions);
  }
};
// NOTE: Modal内でModalを開く場合は閉じてから開く
const selfClosing = () => {
  const open = document.querySelector(".c-micromodal.is-open");
  if (open !== null) {
    MicroModal.close(open.id);
  }
};

const bindMicromodalEventTrigger = (element = document) => {
  const micromodalTrigger = "data-micromodal-trigger";
  const links = element.querySelectorAll(`[${micromodalTrigger}]`);
  links.forEach((link) => {
    link.addEventListener("click", (event) => {
      event.preventDefault();
      const id = link.getAttribute(micromodalTrigger);
      micromodal(id);
    });
  });
};

export { micromodal, bindMicromodalEventTrigger };
