import autosize from "autosize";

const observer = new MutationObserver((mutations) => {
  for (const mutation of mutations) {
    for (const node of mutation.addedNodes) {
      if (node instanceof HTMLElement) {
        autosize(node.querySelectorAll(".js-autosize-textarea"));
      }
    }
  }
});
observer.observe(document.body, { childList: true, subtree: true });

document.addEventListener("DOMContentLoaded", () => {
  autosize(document.querySelectorAll(".js-autosize-textarea"));
});
