document.addEventListener("DOMContentLoaded", () => {
  if (!document.querySelector(".js-section-navigation")) {
    return;
  }
  // セクションごとにナビゲーションをアクティブにする
  const sections = document.querySelectorAll(".js-target-section");
  const nav = document.querySelector(".js-nav");
  const navList = nav.querySelector(".js-nav-list");
  const navLinks = nav.querySelectorAll(".js-nav-link");
  const activeLine = nav.querySelector(".js-active-line");
  const activeClass = "is-active";
  let activeIndex = 0;
  let userScroll = true;

  const setActiveClass = () => {
    navLinks[activeIndex].classList.add(activeClass);
  };
  const removeActiveClass = () => {
    navLinks[activeIndex].classList.remove(activeClass);
  };
  const moveActiveLine = () => {
    const link = navLinks[activeIndex];
    const linkX = link.getBoundingClientRect().x;
    const navListX = navList.getBoundingClientRect().x;
    if (!activeLine) {
      return false;
    } else {
      activeLine.style.transform = `translateX(${navList.scrollLeft - navListX + linkX}px)`;
      activeLine.style.width = `${link.offsetWidth}px`;
    }
  };
  const setNavLeftPosition = (position) => {
    navList.scrollTo({
      left: position,
      behavior: "smooth",
    });
  };
  const checkOverflow = () => {
    const activeLink = navLinks[activeIndex].getBoundingClientRect();
    const offset = 16;
    if (Math.floor(activeLink.right) > window.innerWidth) {
      setNavLeftPosition(navList.scrollLeft + activeLink.right - window.innerWidth + offset);
    } else if (activeLink.left < 0) {
      setNavLeftPosition(navList.scrollLeft + activeLink.left - offset);
    }
  };
  const handleActiveLinkUpdate = (current) => {
    removeActiveClass();
    activeIndex = current;
    checkOverflow();
    setActiveClass();
    moveActiveLine();
  };
  const init = () => {
    moveActiveLine();
  };
  navLinks.forEach((link, target) =>
    link.addEventListener("click", () => {
      userScroll = false;
      handleActiveLinkUpdate(target);
    }),
  );
  const options = { rootMargin: "0% 0% -50% 0%" };
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const currentIndex = Array.prototype.indexOf.call(sections, entry.target);
        if (userScroll && activeIndex !== currentIndex) {
          handleActiveLinkUpdate(currentIndex);
        } else {
          userScroll = true;
        }
      }
    });
  }, options);
  sections.forEach((section) => observer.observe(section));
  init();
});
