window.twttr = (function (d, s, id) {
  var js,
    fjs = d.getElementsByTagName(s)[0],
    t = window.twttr || {};

  if (d.getElementById(id)) return;
  js = d.createElement(s);
  js.id = id;
  js.src = "https://platform.twitter.com/widgets.js";
  fjs.parentNode.insertBefore(js, fjs);

  t._e = [];
  t.ready = function (f) {
    t._e.push(f);
  };

  return t;
})(document, "script", "twitter-wjs");

window.twttr.ready(function (twitter) {
  twitter.events.bind("loaded", function (event) {
    event.widgets.forEach(function (widget) {
      if (widget.parentNode && widget.parentNode.classList.contains("js-tweet-html")) {
        widget.parentNode.classList.remove("tweet-no-loaded");
      }
    });
    Array.prototype.forEach.call(document.querySelectorAll(".js-tweet-html.tweet-no-loaded"), function (node) {
      node.classList.remove("tweet-no-loaded");
      node.classList.add("tweet-load-failed");
    });
  });
});
