import bootstrap from "bootstrap/dist/js/bootstrap";
import Toast from "../common/toast";

$(() => {
  // eslint-disable-next-line no-undef
  if ($("#js-trip_lists").length > 0 && s.Util.getQueryParam("share") === "1") {
    const tripListsShareModal = document.getElementById("target-trip-lists-share");
    if (tripListsShareModal) {
      const bsModal = new bootstrap.Modal(tripListsShareModal);
      bsModal.show();
    }
  }

  // 追加・削除ボタンの有効/無効を制御
  if ($("#js-bulk_clips.bulk_clips-new").length > 0 || $("#js-bulk_clips.bulk_clips-show").length > 0) {
    const notAllChecked = () =>
      $(".js-trip-lists__checkbox")
        .get()
        .every((checkbox) => checkbox.checked === false);
    $(".js-trip-lists__checkbox").on("change", () => {
      $(".js-trip-lists__submit")[0].disabled = notAllChecked();
    });

    $(".js-trip-lists__submit").on("click", () => {
      if (notAllChecked()) {
        alert("クリップが選択されていません。");
        return false;
      }
    });
    $(".js-bulk-clips-form").on("submit", () => {
      const event24Count = $(".js-trip-list-add-presses-checkbox:checked").length;
      const event25Count = $(".js-trip-list-add-spots-checkbox:checked").length;
      // eslint-disable-next-line no-undef
      onclickcatalyst_triplist("ユーザー-Tripリスト-クリップ追加", `event24=${event24Count},event25=${event25Count}`);
      return true;
    });
  }

  $(document).on("ajax:success", "[data-triplist-modal]", ({ target, detail }) => {
    const modalTarget = target.dataset.modalTarget || "#target-lists-create";
    $(modalTarget).html(detail[0].body.innerHTML);

    const tripListModal = new bootstrap.Modal(document.getElementById($(modalTarget).attr("id")));
    tripListModal.show();
  });

  $(document).on("ajax:success", ".js-trip-list-create", ({ detail }) => {
    const { error_form, list_items, list_add, clipable_type } = detail[0];

    if (error_form != null) {
      $("#target-lists-create").html(error_form);
      return;
    }

    const listsCreateEl = document.querySelector("#target-lists-create");
    const listsCreateModal = bootstrap.Modal.getInstance(listsCreateEl);
    listsCreateModal.hide();

    $(".c-no-results h3.c-no-results__heading").hide();
    $("li.c-trip-lists__item--create").after(list_items);
    listAddedToast(list_add);
    tripListAdded(clipable_type);
  });

  $(document).on("ajax:success", ".js-trip-lists-add-link", ({ detail }) => {
    const { error_message, triplist_id, list_item, list_add, clipable_type } = detail[0];

    if (error_message != null) {
      listAddedToast(error_message);
      return;
    }

    $(`#target-list-item-${triplist_id}`).html(list_item);
    listAddedToast(list_add);
    tripListClipAdded(clipable_type);
  });

  const listAddedToast = (addItem) => {
    const listsAddEl = document.getElementById("target-lists-add");
    listsAddEl.innerHTML = addItem;

    const listsAddToast = new Toast(listsAddEl);
    listsAddToast.show();

    const listsEl = document.getElementById("target-lists");
    const listsModal = bootstrap.Modal.getInstance(listsEl);
    if (listsModal) {
      listsModal.hide();
    }

    const clipToListsEl = document.getElementById("target-clip-to-lists");
    const clipToListsModal = bootstrap.Modal.getInstance(clipToListsEl);
    if (clipToListsModal) {
      clipToListsModal.hide();
    }
  };

  const tripListAdded = (clipable_type) => {
    let eventparam = "event23";
    if (clipable_type === "press") eventparam += ",event24=1";
    if (clipable_type === "spot") eventparam += ",event25=1";
    // eslint-disable-next-line no-undef
    onclickcatalyst_triplist($("#catalyst-props").data("prop1").replace("_", "-"), eventparam);
  };

  const tripListClipAdded = (clipable_type) => {
    let eventparam = "";
    if (clipable_type === "press") eventparam += "event24=1";
    if (clipable_type === "spot") eventparam += "event25=1";
    // eslint-disable-next-line no-undef
    onclickcatalyst_triplist($("#catalyst-props").data("prop1").replace("_", "-"), eventparam);
  };
});
