import Toast from "../common/toast";
import { micromodal } from "javascripts/micromodal";

$(() => {
  $("[class*=js-clip-]").removeClass("c-clip--hide");
  $(".js-clips-header-count").show();

  $(document).on("ajax:success", "[class*=js-clip-][data-method=post]", ({ target, detail }) => {
    const { modal_html } = detail[0];
    if (modal_html) {
      loginModal(modal_html);
    } else {
      const [type, id] = target.pathname.split("/").slice(1, 3);
      const page_name = $("#catalyst-props").data("prop1").replace("_", "-");
      // eslint-disable-next-line no-undef
      onclickcatalyst_bookmark_matome(type, id, page_name, $(target).data("clickarea"));

      Array.from(document.getElementsByClassName(`js-clip-${target.dataset.cliptype}-${target.dataset.id}`)).forEach((node) => {
        if (node.classList.contains("c-clip__icon")) node.classList.toggle("c-clip__icon--cliped", true);
        if (node.classList.contains("c-clip__button")) {
          node.classList.toggle("c-clip__button--cliped", true);
          node.textContent = "クリップ解除";
        }
        node.setAttribute("data-method", "delete");
      });
    }
  });

  $(document).on("ajax:success", "[class*=js-clip-][data-method=delete]", ({ target }) => {
    Array.from(document.getElementsByClassName(`js-clip-${target.dataset.cliptype}-${target.dataset.id}`)).forEach((node) => {
      if (node.classList.contains("c-clip__icon")) node.classList.toggle("c-clip__icon--cliped", false);
      if (node.classList.contains("c-clip__button")) {
        node.classList.toggle("c-clip__button--cliped", false);
        node.textContent = "クリップ";
      }
      node.setAttribute("data-method", "post");
      if (target.dataset.cliptype === "press") node.setAttribute("data-popup", "#target-clip-add");
      node.removeAttribute("data-confirm");
    });
  });

  $(document).on("ajax:success", "[class*=js-clip-]", ({ target, detail }) => {
    const { clip_count, no_check_clip_count, toast, triplists } = detail[0];
    if (no_check_clip_count != null) {
      $(".js-clips-count").text(no_check_clip_count);
      $(".js-clips-signal").toggle(no_check_clip_count.toString() !== "0");
    }
    if (clip_count != null) {
      Array.from(document.getElementsByClassName(`js-clip-${target.dataset.cliptype}-${target.dataset.id}`)).forEach((node) => {
        $(node)
          .find(".js-clips-header-count")
          .toggleClass("p-presses-show__clips--empty", !(clip_count > 0))
          .text(`${clip_count}`);
      });
    }

    if (toast) {
      const clipAddEl = document.getElementById("target-clip-add");
      clipAddEl.style.display = "none";
      clipAddEl.innerHTML = toast;

      const clipPopupLinkEl = document.getElementsByClassName("c-clip-popup__link")[0];
      if (clipPopupLinkEl) {
        clipPopupLinkEl.addEventListener("click", function () {
          document.getElementById("target-clip-add").style.display = "none";
        });
      }

      const clipAddToast = new Toast(clipAddEl);
      clipAddToast.show();
    } else {
      document.getElementById("target-clip-add").style.display = "none";
    }

    if (triplists) {
      $("#target-lists").html(triplists)[0].setAttribute("data-cliptype", event.target.dataset.cliptype);
    }
  });

  const loginModal = (html) => {
    if ($(".js-login-modal").length > 0) {
      $(".js-login-modal").replaceWith(html);
    } else {
      $("body").append(html);
    }
    micromodal("target-new-for-ajax");
  };
  $(document).on("ajax:error", ".js-login-form", ({ detail }) => {
    const { error_html } = detail[0];
    $("#js-modal-error").hide().html(error_html).fadeIn();
  });
});
