document.addEventListener("DOMContentLoaded", () => {
  smoothScroll();
});

function smoothScroll() {
  const smoothScroll = document.querySelectorAll(".js-smooth-scroll");
  const summaryButton = document.querySelector("summary[data-target-id]");
  if (smoothScroll) {
    const header = document.querySelector(".js-header");
    const headerHeight = header ? header.clientHeight : "";
    const navi = document.querySelector(".js-nav");
    const naviHeight = navi ? navi.clientHeight : "";

    const hotelsTheme = document.getElementById("js-hotels-theme");
    smoothScroll.forEach((element) => {
      element.addEventListener("click", (event) => {
        let href = element.getAttribute("href");
        if (!href) {
          href = element.getAttribute("data-target-id");
        }
        const target = document.getElementById(href.replace("#", ""));
        // NOTE: 目次には有るがページ内に飛び先が存在しない場合にエラーになるため回避
        if (target) {
          const rect = target.getBoundingClientRect().top;
          const offset = window.pageYOffset || document.documentElement.scrollTop;
          let position = rect + offset - (hotelsTheme ? 32 : naviHeight);
          if (header && position < offset) {
            position = position - headerHeight;
          }
          window.scrollTo({
            top: position,
            behavior: "smooth",
          });
          if (!summaryButton) {
            return event.preventDefault();
          }
        }
        return false;
      });
    });
  }
}
export { smoothScroll };
