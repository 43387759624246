document.addEventListener("DOMContentLoaded", () => {
  const MAX_TOTAL_COUNT = 5;
  const MAX_AGE = 17;
  const personInputs = document.querySelectorAll(".js-stepper-input[id^='person']");
  const childAgeInputs = document.querySelectorAll(".js-child-age");
  const childInputContainer = document.querySelector(".js-child-input-container");
  const personAdultInput = document.getElementById("person-adult");
  let adultCount;
  if (personAdultInput) {
    adultCount = parseInt(personAdultInput.value);
  }
  let childCount = childAgeInputs.length;

  function updateCount(input, increment) {
    let currentValue = parseInt(input.value);
    let newValue = increment ? currentValue + 1 : currentValue - 1;
    if (newValue >= 0) {
      if (input.id.startsWith("person")) {
        if (newValue + (input.id === "person-child" ? adultCount : childCount) <= MAX_TOTAL_COUNT) {
          input.setAttribute("value", newValue);
          if (input.id === "person-child") {
            increment ? addChildInput() : removeChildInput();
          } else {
            adultCount = newValue;
          }
        }
      }
    }
    updateButtonsState();
    outputCount();
  }

  function outputCount() {
    const outputElement = document.querySelector(".js-stepper-input-output");
    if (outputElement) {
      outputElement.textContent = `大人${adultCount}名${childCount > 0 ? `、子供${childCount}名` : ""} / 1室`;
    }
  }

  function updateButtonsState() {
    const totalPersonCount = adultCount + childCount;
    personInputs.forEach((input) => {
      const decrementButton = input.closest(".c-stepper-input__content").querySelector("[data-stepper-input='decrement']");
      const incrementButton = input.closest(".c-stepper-input__content").querySelector("[data-stepper-input='increment']");
      if (input.value === "0") {
        decrementButton.setAttribute("disabled", true);
      } else {
        decrementButton.removeAttribute("disabled");
        positionBottom();
      }
      if (input.classList.contains("js-adult")) {
        if (adultCount <= 1) {
          decrementButton.setAttribute("disabled", true);
        } else {
          decrementButton.removeAttribute("disabled");
        }
      }
      if (totalPersonCount === MAX_TOTAL_COUNT) {
        incrementButton.setAttribute("disabled", true);
        positionBottom();
      } else {
        incrementButton.removeAttribute("disabled");
      }
    });
  }

  function positionBottom() {
    const element = document.querySelector("#target-position-bottom");
    element.scrollIntoView({ behavior: "smooth" });
  }

  function addChildInput() {
    childCount++;
    const childInput = document.createElement("section");
    childInput.classList.add(`js-stepper-input-add-${childCount}`);

    const template = document.querySelector(".js-child-age-input-template").innerHTML;
    let newFieldHtml = template.replace(/NEW_INDEX/g, childCount);
    if (childCount == 0) {
      newFieldHtml = '<p class="c-stepper-input__heading--add">お子様の年齢を入力してください</p>' + newFieldHtml;
    }
    childInput.insertAdjacentHTML("beforeend", newFieldHtml);
    childInputContainer.appendChild(childInput);
    childInputContainer.classList.add("c-stepper-input__container--add");
    childInputBindListener(childInput.querySelector(".js-stepper-input"));
  }

  function removeChildInput() {
    if (childCount > 0) {
      const lastChildInput = document.querySelector(`.js-stepper-input-add-${childCount}`);
      lastChildInput.remove();
      childCount--;
    }
    if (childCount === 0) {
      const addHeading = childInputContainer.querySelector(".c-stepper-input__heading--add");
      if (addHeading) {
        addHeading.remove();
      }
      childInputContainer.classList.remove("c-stepper-input__container--add");
    }
  }

  function childInputBindListener(input) {
    const decrement = input.closest(".c-stepper-input__content").querySelector("[data-stepper-input='decrement']");
    const increment = input.closest(".c-stepper-input__content").querySelector("[data-stepper-input='increment']");
    decrement.addEventListener("click", () => {
      let currentValue = parseInt(input.value);
      currentValue > 0 ? (input.value = currentValue - 1) : 0;
      if (input.id === "person-child") {
        removeChildInput();
      }
    });
    increment.addEventListener("click", () => {
      let currentValue = parseInt(input.value);
      currentValue < MAX_AGE ? (input.value = currentValue + 1) : MAX_AGE;
      if (input.id === "person-child" && currentValue < MAX_AGE) {
        addChildInput();
      }
    });
  }

  if (personInputs[0]) {
    personInputs.forEach((input) => {
      const decrement = input.closest(".c-stepper-input__content").querySelector("[data-stepper-input='decrement']");
      const increment = input.closest(".c-stepper-input__content").querySelector("[data-stepper-input='increment']");
      decrement.addEventListener("click", () => updateCount(input, false));
      increment.addEventListener("click", () => updateCount(input, true));
    });
  }

  if (childAgeInputs[0]) {
    childAgeInputs.forEach((input) => {
      childInputBindListener(input);
    });
  }
  outputCount();
});
